import { css } from 'linaria';

// TODO: css правило повышено намеренно. Детали в тикете https://tracker.yandex.ru/NAVI-4577
export const headerButton = css`
  &[type='button'] {
    width: 100%;
    border: 0;
    display: flex;
    justify-content: space-between;

    padding: var(--space-150) 0;
  }
`;

export const content = css`
  display: flex;
  flex-direction: column;
`;

export const dividerGap = css`
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0.2;
`;

export const chevronDownIcon = css`
  transition: 0.5s;
  transform: rotate(0deg);
`;

export const chevronUpIcon = css`
  transition: 0.5s;
  transform: rotate(-180deg);
`;
