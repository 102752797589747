import React, { memo, useCallback } from 'react';
import { SocialMediaLink } from 'types/model';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { Divider, Typography } from 'fronton-react';
import { analytics, patchUrlByLocale } from 'utils';
import { useTranslation } from 'react-i18next';
import { useHideAkit, useHidePaymentMir, useHideRecTech, useHidePaymentKaspi } from 'common/hooks';
import { AkitLogo, McLogo, MirLogo, VisaLogo, KaspiLogo } from './icons';
import {
  AkitLogoPaymentsWrap,
  desktopText,
  FooterBottomPaymentsWrap,
  FooterBottomWrap,
  FooterPaymentsWrap,
  FooterPolicyAkitWrap,
  FooterSocialLinksWrap,
  FooterSocialPaymentsWrap,
  policyLink,
  PolicyLinks,
  SocialLink,
  socialPolicyDiv,
} from './styles';

export const DATA_QA_SOCIAL_LINK = 'footer-social-link';
export const DATA_QA_AKIT_LOGO_WRAPPER = 'akit-logo-wrapper';

export type FooterSocialLinksProps = {
  links: SocialMediaLink[];
  className?: string;
};

export const FooterSocialLinks = memo<FooterSocialLinksProps>(({ links, className }) => {
  const { path: pathname } = useStoreon<State>('path');
  const {
    cookies: { lang },
  } = useStoreon<State>('cookies');

  const isRecTechVisible = !useHideRecTech();
  const isHideAkit = useHideAkit();
  const isHidePaymentMir = useHidePaymentMir();
  const isHidePaymentKaspi = useHidePaymentKaspi();

  const socialLinkClick = useCallback(
    (title: string) => () => {
      analytics.sendEvent('GA:clickButton', {
        elementType: 'button',
        elementBlock: 'footer',
        elementName: 'socials',
        elementText: title,
      });
    },
    [],
  );
  const { t } = useTranslation();
  return (
    <FooterBottomWrap className={className}>
      <FooterSocialPaymentsWrap>
        <FooterSocialLinksWrap>
          {links.map(({ iconHref, KindAnchor: { text, href, target } }) => (
            <SocialLink
              key={text}
              href={href}
              target={target}
              rel="noopener noreferrer"
              onClick={socialLinkClick(text)}
              data-qa={DATA_QA_SOCIAL_LINK}
            >
              <img src={iconHref} alt={text} />
              <Typography className={desktopText} variant="s" size="body_long" color="text-invert">
                {text}
              </Typography>
            </SocialLink>
          ))}
        </FooterSocialLinksWrap>
        <FooterPaymentsWrap>
          <PaymentLogoList isHidePaymentMir={isHidePaymentMir} isHidePaymentKaspi={isHidePaymentKaspi} />
        </FooterPaymentsWrap>
      </FooterSocialPaymentsWrap>
      <Divider className={socialPolicyDiv} />
      <FooterPolicyAkitWrap>
        <PolicyLinks>
          <a href={patchUrlByLocale({ url: '/politika-obrabotki-personalnykh-dannykh/', pathname, lang })}>
            <Typography variant="s" size="body_long" color="text-invert" className={policyLink}>
              {t('footer.underline.personal')}
            </Typography>
          </a>
          <a href={patchUrlByLocale({ url: '/pravila/', pathname, lang })}>
            <Typography variant="s" size="body_long" color="text-invert" className={policyLink}>
              {t('footer.underline.sell-rules')}
            </Typography>
          </a>
          {isRecTechVisible && (
            <a
              href={patchUrlByLocale({
                url: '/pravila-primeneniya-rekomendatelnyh-tehnologiy/',
                pathname,
                lang,
              })}
            >
              <Typography variant="s" size="body_long" color="text-invert" className={policyLink}>
                {t('footer.underline.technology-rules')}
              </Typography>
            </a>
          )}
        </PolicyLinks>
        <AkitLogoPaymentsWrap>
          <FooterBottomPaymentsWrap>
            <PaymentLogoList isHidePaymentMir={isHidePaymentMir} isHidePaymentKaspi={isHidePaymentKaspi} />
          </FooterBottomPaymentsWrap>
          {!isHideAkit && (
            <div data-qa={DATA_QA_AKIT_LOGO_WRAPPER}>
              <a
                href="https://akit.ru/business/partners/lemana-pro/certificate"
                target="_blank"
                rel="noreferrer"
              >
                <AkitLogo />
              </a>
            </div>
          )}
        </AkitLogoPaymentsWrap>
      </FooterPolicyAkitWrap>
    </FooterBottomWrap>
  );
});

type PaymentLogoListProps = {
  isHidePaymentMir: boolean;
  isHidePaymentKaspi: boolean;
};

const PaymentLogoList = ({ isHidePaymentKaspi, isHidePaymentMir }: PaymentLogoListProps) => {
  return (
    <>
      {!isHidePaymentMir && <MirLogo />}
      <McLogo />
      <VisaLogo />
      {!isHidePaymentKaspi && <KaspiLogo />}
    </>
  );
};
