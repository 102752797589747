import { styled } from 'linaria/react';
import { css } from 'linaria';

export const footerItemListTitle = css`
  padding-top: var(--space-100);
  padding-bottom: var(--space-100);
`;

export const FooterItemList = styled.div`
  width: 100%;
  @media (--from-tablet) {
    display: flex;
    flex-direction: column;
  }
`;

export const FooterMenuItem = styled.a`
  padding-top: var(--space-100);
  padding-bottom: var(--space-100);
  @media (--to-tablet) {
    padding-top: var(--space-150);
    padding-bottom: var(--space-150);
  }
  @media (--only-desktop) {
    max-width: 170px;
  }
  &:hover {
    span {
      color: var(--secondary-yellow-primary) !important;
    }
  }
`;

export const FooterAccordion = styled.div`
  width: 100%;
`;
