import React, { useCallback, useState } from 'react';
import { BaseButton, Divider, Typography } from 'fronton-react';
import { ChevronDownIcon } from '@fronton/icons-react';
import AnimateHeight from 'react-animate-height';
import { chevronDownIcon, chevronUpIcon, content, dividerGap, headerButton } from './style';
import { useTranslation } from 'react-i18next';

export const CONTENT_ITEM_LOCATOR = 'accordion-item-content';
export const EXPAND_BUTTON_LOCATOR = 'accordion-item-header';
const ARIA_CONTROLS_ID = 'panel';

type Props = {
  title: JSX.Element | string;
  onClick?: (open: boolean) => void;
  children: JSX.Element | JSX.Element[] | string;
};

export const AccordionItem = ({ title, children, onClick }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [height, setHeight] = useState<number | 'auto'>(0);

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
    setHeight(height === 0 ? 'auto' : 0);
    onClick?.(isOpen);
  }, [isOpen, height, onClick]);

  return (
    <>
      <HeaderButton text={title} isOpen={isOpen} onClick={handleClick} />
      <AnimateHeight id={ARIA_CONTROLS_ID} duration={500} height={height}>
        <div data-qa={CONTENT_ITEM_LOCATOR} className={content}>
          {children}
        </div>
      </AnimateHeight>
      <Divider className={dividerGap} />
    </>
  );
};

type HeaderButtonProps = {
  text: string | JSX.Element;
  isOpen: boolean;

  onClick: () => void;
};

const HeaderButton = (props: HeaderButtonProps) => {
  const { t } = useTranslation();
  const ariaLabelShowText = t('footer.aria-labels.show-accordion-item', { text: props.text });
  const ariaLabelHideText = t('footer.aria-labels.hide-accordion-item', { text: props.text });

  const ariaLabelCurrentText = props.isOpen ? ariaLabelHideText : ariaLabelShowText;
  const chevronClassName = props.isOpen ? chevronUpIcon : chevronDownIcon;

  return (
    <BaseButton
      className={headerButton}
      data-qa={EXPAND_BUTTON_LOCATOR}
      onClick={props.onClick}
      aria-controls={ARIA_CONTROLS_ID}
      aria-expanded={props.isOpen}
    >
      <Typography variant="m" size="body_accent" color="text-invert">
        {props.text}
      </Typography>
      <ChevronDownIcon
        type="outline"
        size="l"
        color="text-invert"
        aria-label={ariaLabelCurrentText}
        className={chevronClassName}
      />
    </BaseButton>
  );
};
