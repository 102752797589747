import { styled } from 'linaria/react';
import { css } from 'linaria';

export const AkitLogoPaymentsWrap = styled.div`
  @media (--only-mobile) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FooterBottomWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const FooterPolicyAkitWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--from-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FooterPaymentsWrap = styled.div`
  display: block;
  @media (--only-mobile) {
    display: none !important;
  }
`;

export const FooterBottomPaymentsWrap = styled.div`
  display: none;
  @media (--only-mobile) {
    display: block !important;
  }
`;

export const SocialLink = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  @media (--only-mobile) {
    margin-right: var(--space-200);
  }
  @media (--only-tablet) {
    margin-right: var(--space-400);
  }
  &:hover {
    span {
      color: var(--secondary-yellow-primary) !important;
    }
  }
`;

export const PolicyLinks = styled.div`
  display: flex;
  margin-bottom: var(--space-150);
  flex-direction: column;
  @media (--from-tablet) {
    flex-direction: row;
  }
`;

export const FooterSocialPaymentsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FooterSocialLinksWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (--from-desktop) {
    align-items: center;
  }
  img {
    width: 24px;
    height: 24px;
    @media (--to-tablet) {
      width: 40px !important;
      height: 40px !important;
    }
  }
`;

export const policyLink = css`
  opacity: 0.6;
  margin-bottom: var(--space-300);
  &:hover {
    opacity: 1;
  }
  @media (--from-tablet) {
    margin-right: var(--space-200);
    margin-bottom: 0;
  }
`;

export const desktopText = css`
  display: none;
  @media (--from-desktop) {
    display: block;
    margin-left: var(--space-150);
    margin-right: var(--space-300);
    align-self: center;
  }
`;

export const socialPolicyDiv = css`
  margin: var(--space-500) 0;
  opacity: 0.2;
`;
