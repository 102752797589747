import React from 'react';
import { Badge, Typography, LogoLemanaPro } from 'fronton-react';
import { ONELINK_URL_PATH_BY_DEVICE } from 'utils/onelink-url';
import { useOpenlinkUrl } from 'common/hooks';
import { useTranslation } from 'react-i18next';
import { detectDevice } from 'utils/detect-device';
import {
  AppBlock,
  FooterAppWrapper,
  lemanaProLogo,
  logoBadge,
  LogoBlock,
  logoText,
  logoWithBadge,
  logoWrap,
  roundLogoBadge,
  roundLogoWrap,
} from './styles';

export const DATA_QA_APPSTORE_URL = 'appstore-url';
export const DATA_QA_GOOGLEPLAY_URL = 'googleplay-url';
export const DATA_QA_APPGALLERY_URL = 'appgallery-url';

export const DATA_QA_APPSTORE_IMAGE = 'appstore-image';
export const DATA_QA_GOOGLEPLAY_IMAGE = 'googleplay-image';
export const DATA_QA_APPGALLERY_IMAGE = 'appgallery-image';

export const HUAWEI_CDN_URL =
  'https://cdn.lemanapro.ru/lmru/image/upload/c_pad,f_auto,q_auto,dpr_1.0,w_166,h_48/v1/other/navigation-microfronts/header-footer/app-gallery.png/file.png';
export const IPHONE_CDN_URL =
  'https://cdn.lemanapro.ru/lmru/image/upload/c_pad,f_auto,q_auto,dpr_1.0,w_166,h_48/v1/other/navigation-microfronts/header-footer/ios.png/file.png';
export const ANDROID_CDN_URL =
  'https://cdn.lemanapro.ru/lmru/image/upload/c_pad,f_auto,q_auto,dpr_1.0,w_166,h_48/v1/other/navigation-microfronts/header-footer/gp.png/file.png';
export const ANDROID_CDN_2X =
  'https://cdn.lemanapro.ru/lmru/image/upload/c_pad,f_auto,q_auto,dpr_2.0,w_166,h_48/v1/other/navigation-microfronts/header-footer/gp.png/file.png 2x';
export const IPHONE_CDN_2X =
  'https://cdn.lemanapro.ru/lmru/image/upload/c_pad,f_auto,q_auto,dpr_2.0,w_166,h_48/v1/other/navigation-microfronts/header-footer/ios.png/file.png 2x';
export const HUAWEI_CDN_2X =
  'https://cdn.lemanapro.ru/lmru/image/upload/c_pad,f_auto,q_auto,dpr_2.0,w_166,h_48/v1/other/navigation-microfronts/header-footer/app-gallery.png/file.png 2x';

export const FooterAppBlock: React.FC = () => {
  const googleplayUrl = useOpenlinkUrl(ONELINK_URL_PATH_BY_DEVICE.android.mobile, 'android', 'mobile');
  const appstoreUrl = useOpenlinkUrl(ONELINK_URL_PATH_BY_DEVICE.os.mobile, 'os', 'mobile');
  const appgalleryUrl = useOpenlinkUrl(ONELINK_URL_PATH_BY_DEVICE.huawei.mobile, 'huawei', 'mobile');
  const { iphone, android, huawei } = !__IS_SERVER__
    ? detectDevice()
    : { iphone: false, android: false, huawei: false };

  const { t } = useTranslation();

  return (
    <FooterAppWrapper>
      <LogoBlock>
        <div className={logoWithBadge}>
          <div className={iphone ? logoWrap : roundLogoWrap}>
            <LogoLemanaPro className={lemanaProLogo} />
          </div>
          <Badge className={iphone ? logoBadge : roundLogoBadge} size="xs" value={1} />
        </div>
        <Typography size="subtitle" variant="m" className={logoText}>
          {t('footer.app.message')}
        </Typography>
      </LogoBlock>
      <AppBlock>
        {iphone && (
          <a href={appstoreUrl} target="_blank" rel="noopener noreferrer" data-qa={DATA_QA_APPSTORE_URL}>
            <img
              src={IPHONE_CDN_URL}
              srcSet={IPHONE_CDN_2X}
              alt="ios-app"
              data-qa={DATA_QA_APPSTORE_IMAGE}
            />
          </a>
        )}
        {android && !huawei && (
          <a href={googleplayUrl} target="_blank" rel="noopener noreferrer" data-qa={DATA_QA_GOOGLEPLAY_URL}>
            <img
              src={ANDROID_CDN_URL}
              srcSet={ANDROID_CDN_2X}
              alt="gp-app"
              data-qa={DATA_QA_GOOGLEPLAY_IMAGE}
            />
          </a>
        )}
        {huawei && (
          <a href={appgalleryUrl} target="_blank" rel="noopener noreferrer" data-qa={DATA_QA_APPGALLERY_URL}>
            <img
              src={HUAWEI_CDN_URL}
              srcSet={HUAWEI_CDN_2X}
              alt="ag-app"
              data-qa={DATA_QA_APPGALLERY_IMAGE}
            />
          </a>
        )}
      </AppBlock>
    </FooterAppWrapper>
  );
};
