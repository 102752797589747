import { getUserAgent } from './ua';
import { isAndroid, isHuawei, isIPhone } from './ua-detector';

export const detectDevice = () => {
  const ua = getUserAgent();
  return {
    iphone: isIPhone(ua),
    android: isAndroid(ua),
    huawei: isHuawei(ua),
  };
};
