import { KindAnchor } from 'types/model';
import React from 'react';
import { useStoreon } from 'storeon/react';
import { State } from 'store/types';
import { patchUrlByLocale } from 'utils';
import { useBreakpoints } from 'common/hooks';
import { Typography } from 'fronton-react';
import { onMenuLinkClickCb } from 'components/footer-list/utils/ga-events';
import { AccordionItem } from '../accordion';
import { FooterAccordion, FooterItemList, footerItemListTitle, FooterMenuItem } from './styles';

export const DATA_QA_FOOTER_ACCORDION = 'footer-accordion';
export const DATA_QA_FOOTER_MENU_ITEM = 'footer-menu-item';
export const DATA_QA_FOOTER_ITEM_LIST = 'footer-item-list';
export const DATA_QA_FOOTER_ITEM_LIST_TITLE = 'footer-item-list-title';

type FooterListTabProps = {
  name: string;
  metaContent: string;
  links: KindAnchor[];
};

export const FooterListTab: React.FC<FooterListTabProps> = ({ name, metaContent, links }) => {
  const { path: pathname } = useStoreon<State>('path');
  const {
    cookies: { lang },
  } = useStoreon<State>('cookies');
  const breakpoints = useBreakpoints();

  return (
    <>
      {breakpoints?.mobile ? (
        <FooterAccordion data-qa={DATA_QA_FOOTER_ACCORDION}>
          <AccordionItem key={name} title={name}>
            <InnerMenuList
              links={links}
              pathname={pathname}
              lang={lang}
              textLocator={DATA_QA_FOOTER_ITEM_LIST_TITLE}
            />
          </AccordionItem>
        </FooterAccordion>
      ) : (
        <FooterItemList key={name} data-qa={DATA_QA_FOOTER_ITEM_LIST}>
          <Typography
            variant="m"
            size="subtitle"
            color="text-invert"
            className={footerItemListTitle}
            data-qa={DATA_QA_FOOTER_ITEM_LIST_TITLE}
          >
            {name}
          </Typography>
          <meta itemProp="name" content={metaContent} />

          <InnerMenuList links={links} pathname={pathname} lang={lang} />
        </FooterItemList>
      )}
    </>
  );
};

type InnerMenuListProps = {
  links: KindAnchor[];
  lang: string;
  pathname: string;
  textLocator?: string;
};

const InnerMenuList = ({ links, lang, pathname, textLocator }: InnerMenuListProps) => {
  return (
    <>
      {links.map(({ text, href, target }) => (
        <FooterMenuItem
          key={text}
          onClick={() => onMenuLinkClickCb(text)}
          itemProp="url"
          href={patchUrlByLocale({ url: href, pathname, lang })}
          target={target}
          data-qa={DATA_QA_FOOTER_MENU_ITEM}
          title={text}
        >
          <Typography variant="s" size="body_long" color="text-invert" data-qa={textLocator}>
            {text}
          </Typography>
        </FooterMenuItem>
      ))}
    </>
  );
};
